import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { PinLocationIcon } from '@src/icons/pin-location'
import { PartnersContext } from '@src/react-context/partners-context'
import { Partner } from '@src/types/strapi/collections/partners'
import { useContext } from 'react'

type PartnerCardProps = {
  partner: Partner
}

const capitalizeFistLatter = (text: string) => {
  return text.replace(/\b\w+\b/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })
}

const getFullAddress = (partner: Partner) => {
  return `${capitalizeFistLatter(`${partner.address}, ${partner.neighborhood}, ${partner.city}`)} - ${partner.uf}`
}

const PartnerCard = ({ partner }: PartnerCardProps) => {
  const { setPartnerSelected } = useContext(PartnersContext)
  return (
    <Flex
      direction={'column'}
      maxWidth={'18rem'}
      wrap={'wrap'}
      cursor={'pointer'}
      onClick={() => setPartnerSelected(partner)}
    >
      <Flex direction={'row'} alignItems={'center'}>
        <Icon color="blue.500" boxSize={'32px'} alignSelf={'self-start'} marginTop={'0.2rem'}>
          <PinLocationIcon />
        </Icon>
        <Flex direction={'column'} fontSize={'1rem'}>
          <Text fontWeight={'700'} color={'#052D5F'}>
            {partner.business_name}
          </Text>
          <Text fontWeight={'400'} fontSize={'0.875rem'} color={'#4D5163'}>
            {partner.cnpjcpf}
          </Text>
          <Box marginTop={'0.5rem'} fontSize={'0.875rem'} fontWeight={'400'} color={'#4D5163'}>
            <Text>{getFullAddress(partner)}</Text>
            <Text>{`CEP ${partner.cep}`}</Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PartnerCard
