import { FormControl, InputProps } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'
import FormError from '../FormError'
import FormLabel from '../FormLabel'
import styles from './MoneyInput.module.scss'

interface MoneyInputProps {
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  maxLength: number
  onChangeEvent?: (any) => any
  value?: string
  variant?: 'default' | 'simulator'
  className?: string
  color?: string
  disableErrorMessage?: boolean
}

const INTL_CONFIG = { locale: 'pt-BR', currency: 'BRL' }

const MoneyInput: React.FC<MoneyInputProps & InputProps> = ({
  label,
  name,
  required,
  placeholder,
  variant,
  disabled,
  maxLength,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const renderInput = useCallback(
    (props: ControllerRenderProps<Record<string, any>>) => {
      return (
        <CurrencyInput
          onValueChange={props.onChange}
          value={props.value}
          ref={props.ref}
          className={variant === 'simulator' ? styles.simulatorInput : styles.input}
          maxLength={maxLength}
          placeholder={placeholder}
          aria-invalid={Boolean(errors[name]?.message)}
          allowDecimals
          disabled={disabled}
          intlConfig={INTL_CONFIG}
        />
      )
    },
    [disabled, errors]
  )

  return (
    <FormControl justifyContent="space-between">
      <FormLabel required={required}>{label}</FormLabel>
      <Controller control={control} name={name} render={renderInput as any} />
      {!rest.disableErrorMessage && <FormError>{errors[name]?.message}</FormError>}
    </FormControl>
  )
}

export default MoneyInput
