import { ProductsListFiltersData } from '@components/Sections/ProductsList/ProductsListFilter'
import { GetProductsQuantityRequest } from '@src/service/products'

export const mapFiltersToGetProductsQuantityRequest = (
  filters: ProductsListFiltersData
): GetProductsQuantityRequest => ({
  title_contains: filters.name ? filters.name : undefined,
  firmness: filters.firmness,
  category: filters.category ? +filters.category : undefined,
})
