import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { PinPointIcon } from '@src/icons'
import { Store } from '@src/types/strapi'
import { memo } from 'react'
import StoreDetails from '../StoreDetails'

interface StoreListProps {
  stores: Store[]
}

const StoreList = ({ stores }: StoreListProps) => {
  if (!stores.length) return null

  return (
    <Accordion allowToggle gridGap={'0.5rem'} display={'flex'} flexDirection={'column'}>
      {stores.map(store => (
        <AccordionItem
          key={store.cnpj}
          borderRadius="8px"
          borderStyle="solid"
          borderWidth="thin"
          borderColor="gray.150"
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <VStack width="full">
                    <HStack width="full" justifyContent="space-between">
                      <Box as="span" flex="1" textAlign="left">
                        <Flex
                          flexDirection={{ base: 'column', md: 'row' }}
                          gridColumnGap={2}
                          gridRowGap={1}
                          alignItems={{ base: 'flex-start', md: 'center' }}
                          justifyContent="flex-start"
                        >
                          <HStack color="blue.500" spacing={1} alignItems="center">
                            <PinPointIcon height="18px" width="18px" />
                            <Text
                              id="aqui"
                              fontWeight={700}
                              fontSize="16px"
                              lineHeight="19.36px"
                              textTransform={'lowercase'}
                              sx={{
                                ':first-letter': {
                                  textTransform: 'capitalize',
                                },
                              }}
                            >
                              {store.address.city}
                            </Text>
                            <Text fontWeight={700} fontSize="16px" lineHeight="19.36px">
                              - {store.address.state}
                            </Text>
                          </HStack>
                          <Text color="gray.500" fontWeight={400} fontSize="14px" lineHeight="14.52px">
                            (Filial {store.branchNumber})
                          </Text>
                        </Flex>
                      </Box>
                      <AccordionIcon color="blue.500" />
                    </HStack>
                  </VStack>
                </AccordionButton>
              </h2>
              {isExpanded && (
                <Flex paddingX="1rem">
                  <Divider />
                </Flex>
              )}
              <AccordionPanel>
                <StoreDetails {...store} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default memo(StoreList)
