import { Box } from '@chakra-ui/core'
import { StrapiSpacing } from '@src/types/strapi'

const makeSpacingInPx = (spacing: number) => `${spacing}px`

export const Spacing = (props: StrapiSpacing) => {
  return (
    <Box
      width="100%"
      height={{
        base: makeSpacingInPx(props.spacing_mobile),
        md: makeSpacingInPx(props.spacing_desktop),
      }}
    />
  )
}
