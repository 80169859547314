import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Heading,
  VStack,
} from '@chakra-ui/react'
import { StoresByState } from '@src/types/strapi'
import { memo } from 'react'
import StoreList from '../StoreList'

interface StateStoresProps {
  storesByState: StoresByState[]
  selectedStateInitials?: string
}

const RenderAccordionItemState = memo(({ state }: { state: StoresByState }) => {
  return (
    <AccordionItem borderRadius="8px" borderStyle="solid" borderWidth="thin" borderColor="gray.150" width="full">
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading as="h2" fontWeight={700} fontSize={{ base: '1.5rem', md: '2rem' }} color={'gray.800'}>
                  {state.name}
                </Heading>
              </Box>
              <AccordionIcon color="blue.500" />
            </AccordionButton>
          </h2>
          {isExpanded && (
            <Flex paddingX="1rem">
              <Divider />
            </Flex>
          )}
          <AccordionPanel padding="16px">
            <StoreList stores={state.items} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
})

RenderAccordionItemState.displayName = 'RenderAccordionItemState'

const StateStores = ({ storesByState, selectedStateInitials }: StateStoresProps) => {
  return (
    <Accordion width="full" defaultIndex={[0]} allowToggle>
      <VStack width="full" gridGap={{ base: '0.25rem', md: '1rem' }}>
        {storesByState
          .filter(state => !selectedStateInitials || state.initials === selectedStateInitials)
          .map(state => (
            <RenderAccordionItemState key={state.initials} state={state} />
          ))}
      </VStack>
    </Accordion>
  )
}

export default StateStores
