import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { useFirmness } from '@src/hooks/api'

export interface FirmnessSelectProps {
  value?: number
  onChange: (value: number) => void
}

export const FirmnessSelect = (props: FirmnessSelectProps) => {
  const { data } = useFirmness()

  return (
    <FormControl id="firmness" width="full" height="auto" minWidth="250px">
      <FormLabel fontSize="1.25rem" fontWeight="bold">
        Maciez
      </FormLabel>
      <Select
        name="firmness"
        value={props.value}
        onChange={e => props.onChange(+e.target.value)}
        width="full"
        height="3.25rem"
      >
        <option value={0}>-</option>
        {data?.map(item => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}
