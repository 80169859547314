import { Button, Flex, Grid, GridItem, Heading, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/nextjs'
import { ContactUsFormPayload, sendEmailContactUs } from '@src/service/contact-us'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CitySelect, ContactTypesSelect, InputPhone, InputText, InputTextArea, StateSelect } from './Form'

export interface ContactUsFormProps {
  formTitle: string
  formSubtitle: string
}

const schemaValidation = yup.object().shape({
  contactType: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Digite um email válido').email('E-mail inválido').required('Campo obrigatório'),
  telephone: yup.string().min(10, 'Preencha corretamente o telefone').required('Campo obrigatório'),
  state: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),
  subject: yup.string().required('Campo obrigatório'),
  message: yup.string().required('Campo obrigatório'),
})

export const ContactUsForm = (props: ContactUsFormProps) => {
  const toast = useToast()
  const form = useForm<ContactUsFormPayload>({
    resolver: yupResolver(schemaValidation),
  })

  const handleSubmit = async (data: ContactUsFormPayload) => {
    try {
      await sendEmailContactUs(data)

      toast({
        title: 'Obrigado pelo contato!',
        description: 'Retornaremos o mais breve possível.',
        status: 'success',
        position: 'top-right',
      })
    } catch (error) {
      Sentry.captureException(error)

      toast({
        title: 'Ocorreu um erro ao enviar o formulário.',
        description: 'Tente novamente mais tarde.',
        status: 'error',
        position: 'top-right',
      })
    }
  }

  return (
    <VStack width={'full'} gridGap={'2rem'}>
      <VStack gridGap={'0.375rem'} width={'full'}>
        <Heading as={'h2'} fontSize={'1.375rem'} fontWeight={'600'} color={'gray.900'}>
          {props.formTitle}
        </Heading>
        <Heading as={'h3'} fontSize={'0.875rem'} fontWeight={'400'} color={'gray.500'}>
          {props.formSubtitle}
        </Heading>
      </VStack>
      <FormProvider {...form}>
        <Grid
          width={'full'}
          gridRowGap={{ base: '1rem', md: '1.5rem' }}
          gridColumnGap={{ base: '1rem', md: '1.875rem' }}
          gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          as={'form'}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <ContactTypesSelect label={'Tipo de Contato'} name={'contactType'} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <InputText label={'Nome Completo'} name={'name'} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <InputText label={'E-mail'} name={'email'} type={'email'} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <InputPhone label={'Telefone'} name={'telephone'} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <StateSelect name={'state'} label={'Estado'} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <CitySelect name={'city'} label={'Cidade'} />
          </GridItem>
          <GridItem colSpan={2}>
            <InputText label={'Assunto'} name={'subject'} />
          </GridItem>
          <GridItem colSpan={2}>
            <InputTextArea label="Mensagem" name={'message'} />
          </GridItem>
          <GridItem colSpan={2} justifyContent={'center'}>
            <Flex flex={1} width={'full'} justifyContent={'center'}>
              <Button
                type={'submit'}
                height={{ base: '3.375rem' }}
                width={{ base: 'full', md: '17.3125rem' }}
                variant={'solid'}
                colorScheme={'blue'}
                isLoading={form.formState.isSubmitting}
              >
                Enviar
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </FormProvider>
    </VStack>
  )
}
