import { FormControl, Select, SelectProps } from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import FormError from '../FormError'
import FormLabel from '../FormLabel'

interface SelectOptions {
  key: string | number
  label: string
}

interface SelectInputProps {
  label: string
  name: string
  options: Array<SelectOptions>
  required?: boolean
  disabled?: boolean
  defaultValue?: string
}

const SelectInput: React.FC<SelectInputProps & SelectProps> = ({
  label,
  name,
  required,
  disabled,
  options,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl>
      <FormLabel required={required}>{label}</FormLabel>
      <Select {...register(name)} data-testid={name} isDisabled={disabled} {...rest}>
        {options.map(option => (
          <option key={option.key} value={option.key}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormError>{errors[name]?.message}</FormError>
    </FormControl>
  )
}

export default SelectInput
