import { ProductsListFiltersData } from '@components/Sections/ProductsList/ProductsListFilter'
import { COUNT_PRODUCTS_ENDPOINT, getProductsQuantity } from '@src/service/products'
import { makeQueryStringFromObject } from '@src/utils/make-query-string-from-object'
import { useMemo } from 'react'
import useSWR from 'swr'
import { mapFiltersToGetProductsQuantityRequest } from './mappers'

export const useProductCount = (filters: ProductsListFiltersData, itemsPerPage: number) => {
  const productsCountRequestData = useMemo(() => mapFiltersToGetProductsQuantityRequest(filters), [filters])

  return useSWR<number>(
    `${COUNT_PRODUCTS_ENDPOINT}?${makeQueryStringFromObject(productsCountRequestData)}`,
    async () => {
      const totalOfRecords = await getProductsQuantity(productsCountRequestData)
      const quantity = Math.floor(totalOfRecords / itemsPerPage)

      return totalOfRecords % itemsPerPage > 0 ? quantity + 1 : quantity
    }
  )
}
