import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core'
import Form from '@components/Form'
import InputWithSlider from '@components/InputWithSlider'
import { formatValueMonetary } from '@components/InvestimentChart'
import LoadingOverlay from '@components/LoadingOverlay'
import PopoverList from '@components/PopoverList'
import { ICards, IItemSimulator } from '@components/Sections/InvestmentSimulator/InvestimentSimulator.interface'
import SelectInput from '@components/SelectInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/nextjs'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ISendValues, SimulationResult } from './SimulatorCard.interface'
import styles from './SimulatorCard.module.scss'

export const styleTab = {
  // css not working correct
  style: {
    boxShadow: 'none !important',
    color: '#3D9DEF',
    fontFamily: 'Poppins',
    fontSize: '18px',
  },
}

const LABELS = [
  { key: 'ValorSolicitado', label: 'Valor Solicitado', type: 'string' },
  { key: 'IOFExibir', label: 'IOF', type: 'string' },
  { key: 'TFCExibir', label: 'TFC', type: 'string' },
  { key: 'TACExibir', label: 'TAC', type: 'string' },
  { key: 'SeguroExibir', label: 'Seguro', type: 'string' },
  { key: 'ValorFinanciadoExibir', label: 'Valor Financiado', type: 'string' },
  { key: 'Prazo', label: 'Quantidade de parcelas', type: 'string' },
  { key: 'PMT', label: 'Valor da Parcela', type: 'money' },
  { key: 'TaxaJuros', label: 'Taxa de Juros', type: 'string' },
  { key: 'CET', label: 'CET Mensal', type: 'percentage' },
  { key: 'CETAnual', label: 'CET Anual', type: 'percentage' },
  { key: 'Emissao', label: 'Data Emissão', type: 'date' },
  { key: 'PrimeiroVencimento', label: 'Data 1º Vencimento', type: 'date' },
]

const Card: FC<IItemSimulator> = ({ type, item, amounts, onSimulate }) => {
  const [loading, setLoading] = useState(true)

  const [resultValue, setResultValue] = useState<SimulationResult>({})

  const defaultValues = ({ value }) => ({
    value: value.min.toString(),
  })

  const validSlider = ({ max, min }) => {
    const messageValidator = `Insira um valor entre R$ ${formatValueMonetary(min)} e R$ ${formatValueMonetary(max)}`

    return yup.string().test('isValid', messageValidator, inputValue => {
      if (!inputValue) {
        inputValue = min
      }

      if (!inputValue) return false

      return parseFloat(inputValue) <= max && parseFloat(inputValue) >= min
    })
  }

  const yupValidation = ({ value }) => {
    return {
      value: validSlider(value),
    }
  }

  const schema = yup.object().shape(yupValidation(item))

  const form = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues(item),
  })

  const { value: loanValue, amount } = form.watch(['value', 'amount']) as any

  const requestSimulation = useCallback(
    async ({ value, amount: requestAmount }) => {
      try {
        if (!value || !requestAmount) return
        setLoading(true)
        const parsedValue = typeof value === 'string' ? value.replace(/\./gm, '').replace(',', '.') : value
        const data = await onSimulate(parsedValue, requestAmount, type)
        setResultValue(data)
      } catch (ex) {
        Sentry.captureException(ex)
      } finally {
        setLoading(false)
      }
    },
    [type, onSimulate]
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const values = form.getValues() as Partial<ISendValues>
      if (!form.formState.errors) requestSimulation(values)
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [loanValue, amount, requestSimulation])

  const renderGreencard = ({ greenCard_label, greenCard_info }) => {
    const valueToDisplay = Number(type === 'amount' ? resultValue.PMT : resultValue.ValorLiberar)
    return (
      <div className={styles.greenCard}>
        <div className={styles.labelCard}>
          {greenCard_label}
          <img src="/arrow-b.svg" />
          {'R$'}
          <div className={styles.monetaryValue}>{formatValueMonetary(valueToDisplay || 0)}</div>
          {Boolean(valueToDisplay) && (
            <PopoverList data={resultValue} labels={LABELS}>
              <div className={styles.imgPopover}>
                <img src="/info.svg" width={20} height={20} />
              </div>
            </PopoverList>
          )}
        </div>
        <div className={styles.greenCardInfo}>{greenCard_info}</div>
      </div>
    )
  }

  return (
    <div className={styles.card}>
      <LoadingOverlay loading={loading}>
        <Form form={form} onSubmit={() => null}>
          <InputWithSlider name="value" {...item.value} />
          <SelectInput label="Qtd. de parcelas:" name="amount" options={amounts} />
          {renderGreencard(item)}
        </Form>
      </LoadingOverlay>
    </div>
  )
}

Card.displayName = 'Card'

export const SimulatorCard: FC<ICards> = ({ items, amounts, onSimulate }) => {
  return (
    <Tabs align="center" isFitted borderColor="">
      <TabList>
        {items.map(card => (
          <Tab {...styleTab} key={card.id}>
            {card.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {items.map(card => (
          <TabPanel key={card.id}>
            <Card type={card.type} item={card.item} amounts={amounts} onSimulate={onSimulate} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default SimulatorCard
