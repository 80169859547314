import { Box, IconButton } from '@chakra-ui/react'
import { ImageCommonComponent } from '@components/Common/Image'
import { ArrowLeftIcon, ArrowRightIcon } from '@src/icons'
import { StrapiBannerCarousel } from '@src/types/strapi'
import { carouselDelayInMs } from '@src/utils/delay'
import { useRef } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './BannerCarousel.module.scss'

export const BannerCarousel = (props: StrapiBannerCarousel) => {
  const swiperRef = useRef<any>(null)
  const hasMoreThanOneItem = props.items.length > 1

  return (
    <Box height={{ base: '200px', md: '300px', lg: '300px', xl: '420px', '2xl': '560px' }}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        slidesPerGroup={1}
        className={styles.customCarousel}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        autoplay={{
          enabled: hasMoreThanOneItem,
          delay: carouselDelayInMs(props.timeout),
        }}
        pagination={{
          clickable: true,
          enabled: true,
          bulletClass: `swiper-pagination-bullet ${styles.customBullet}`,
          bulletActiveClass: `swiper-pagination-bullet-active ${styles.customBulletActive}`,
        }}
      >
        {props.items.map(item => {
          return (
            <SwiperSlide key={item.id} className={styles.customCarouselItem}>
              {item.link ? (
                <a href={item.link} target={item.isExternal ? '_blank' : '_self'}>
                  <ImageCommonComponent {...item} />
                </a>
              ) : (
                <ImageCommonComponent {...item} />
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
      {hasMoreThanOneItem && (
        <>
          <IconButton
            icon={<ArrowLeftIcon />}
            variant="unstyled"
            display={{ base: 'none', md: 'initial' }}
            aria-label="prev-button"
            position="relative"
            top="-60%"
            left="14%"
            backgroundColor="white"
            borderRadius="100%"
            zIndex={2}
            width="48px"
            height="48px"
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <IconButton
            icon={<ArrowRightIcon />}
            display={{ base: 'none', md: 'initial' }}
            variant="unstyled"
            aria-label="next-button"
            backgroundColor="white"
            position="relative"
            top="-60%"
            left="80%"
            zIndex={2}
            borderRadius="100%"
            width="48px"
            height="48px"
            onClick={() => swiperRef.current?.slideNext()}
          />
        </>
      )}
    </Box>
  )
}
