import { Input, InputProps } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface InputTextProps extends InputProps {
  label: string
  name: string
}

export const InputText = ({ label, name, ...props }: InputTextProps) => {
  const { register, formState } = useFormContext()

  return (
    <FormItem label={label} errorMessage={getErrorMessageFromFormState(formState, name)}>
      <Input {...register(name)} placeholder={label} {...props} height={'3rem'} fontSize={'0.875rem'} />
    </FormItem>
  )
}
