export const getSearchParams = (): Record<string, any> => {
  if (typeof window === 'undefined') return {}

  const searchParams = new URLSearchParams(window.location.search)

  const params = {}

  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }

  return params
}
