import { Input } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface InputPhoneProps {
  label: string
  name: string
}

const PHONE_MASK_WITH_ELEVEN_DIGITS = '(99) 9 9999-9999'

export const InputPhone = (props: InputPhoneProps) => {
  const { watch, setValue, formState } = useFormContext()
  const value = watch(props.name, '')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(props.name, event.target.value?.replace(/\D/g, ''))
  }

  return (
    <FormItem label={props.label} errorMessage={getErrorMessageFromFormState(formState, props.name)}>
      <Input
        as={InputMask}
        mask={PHONE_MASK_WITH_ELEVEN_DIGITS}
        placeholder={'Telefone'}
        height={'3rem'}
        value={value}
        onChange={handleChange}
      />
    </FormItem>
  )
}
