import { Icon, IconProps } from '@chakra-ui/react'

export const PinLocationIcon = (props: IconProps) => {
  return (
    <Icon width="32px" height="33px" viewBox="0 0 32 33" fill="none" {...(props as any)}>
      <path
        d="M13.116 18.6187C9.562 19.0867 7 20.3917 7 21.9317C7 23.8647 11.029 25.4317 16 25.4317C20.971 25.4317 25 23.8647 25 21.9317C25 20.3917 22.438 19.0867 18.884 18.6187"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.2646C22 9.04364 19.314 6.43164 16 6.43164C12.686 6.43164 10 9.04364 10 12.2646C10 16.6396 16 21.4316 16 21.4316C16 21.4316 22 16.6396 22 12.2646Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4142 11.0174C18.1953 11.7985 18.1953 13.0648 17.4142 13.8459C16.6332 14.6269 15.3668 14.6269 14.5858 13.8459C13.8047 13.0648 13.8047 11.7985 14.5858 11.0174C15.3668 10.2364 16.6332 10.2364 17.4142 11.0174"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
