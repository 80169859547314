import { Flex } from '@chakra-ui/react'
import { StateInfo } from '@src/types/strapi'
import { Dispatch, memo, SetStateAction } from 'react'
import StateButton from '../StateButton'

export interface StateFilterProps {
  states: StateInfo[]
  onClick: Dispatch<SetStateAction<string | undefined>>
  selectedStateInitials?: string
}

const StateFilter = ({ states, onClick, selectedStateInitials }: StateFilterProps) => {
  return (
    <Flex maxWidth="full" flexWrap="wrap" flexDirection="row" gridGap={2}>
      <StateButton name="Todos" onClick={onClick} selected={selectedStateInitials === undefined} />
      {states.map(state => (
        <StateButton
          key={state.initials}
          onClick={onClick}
          selected={state.initials === selectedStateInitials}
          {...state}
        />
      ))}
    </Flex>
  )
}

export default memo(StateFilter)
