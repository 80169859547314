import * as yup from 'yup'

export const validateRequired = yup.string().required('Campo obrigatório')

export const validatePhone = yup.string().matches(/\([0-9]{2}\) [0-9]{4}-[0-9]{4}/, {
  message: 'Insira um telefone válido',
  excludeEmptyString: true,
})

export const validateCelphone = yup.string().matches(/\([0-9]{2}\) [0-9]{5}-[0-9]{4}/, {
  message: 'Insira um celular válido',
  excludeEmptyString: true,
})

export const validatePhoneOrCelphone = yup.string().matches(/\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}/, {
  message: 'Insira um telefone ou celular válido',
  excludeEmptyString: true,
})

export const validateCEP = yup
  .string()
  .matches(/[0-9]{5}-[0-9]{3}/im, { message: 'Insira um CEP válido' })
  .required('Campo obrigatório')

// Start of validate file
const SUPPORTED_FORMATS = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']

const FILE_SIZE = 1024 * 1024 * 3

export const validateMoney = yup.string().test('isValid', 'Insira um valor até R$ 999.999.999.999,99', inputValue => {
  if (!inputValue) return true

  return parseFloat(inputValue) <= 999999999999.99
})

export const validateFile = yup
  .mixed()
  .test('isRequired', 'Campo obrigatório', (value: any) => {
    if (!value) return false
    const file = value.item(0)
    return Boolean(file)
  })
  .test('fileSize', 'Arquivo muito grande. Tamanho máximo 3MB', (value: any) => {
    if (!value) return false
    const file = value.item(0)
    return file && file.size <= FILE_SIZE
  })
  .test('fileFormat', 'Tipo do arquivo não suportado', (value: any) => {
    if (!value) return false
    const file = value.item(0)
    return file && SUPPORTED_FORMATS.includes(file.type)
  })

// End of validate file

export const isValidCPF = cpf => {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[\s.-]*/gim, '')
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export const isValidCNPJ = value => {
  if (!value) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes = typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)

  // Elimina valor em formato inválido
  if (!validTypes) return false

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g)
  const numbers = Array.isArray(match) ? match.map(Number) : []

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  // Cálculo validador
  const calc = x => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12)

  // Valida 1o. dígito verificador
  const digit0 = calc(12)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = calc(13)
  return digit1 === digits[1]
}

export const validateCPF = yup.string().test('isValid', 'Informe um CPF válido', cpf => {
  if (!cpf) return true
  const cpfWithoutMask = cpf?.replace(/\D/g, '') || ''
  return isValidCPF(cpfWithoutMask)
})

export const yupValidateCNPJ = yup.string().test('isValid', 'Informe um CNPJ válido', cnpj => {
  if (!cnpj) return true
  const cnpjWithoutMask = cnpj?.replace(/\D/g, '') || ''
  return isValidCNPJ(cnpjWithoutMask)
})
