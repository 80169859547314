import * as Sentry from '@sentry/nextjs'
import { ContactType } from '@src/types/strapi'
import { api } from './api'

export const CONTACT_TYPES_ENDPOINT = '/contact-types'

export const getContactTypes = async (): Promise<ContactType[]> => {
  try {
    return api.get<ContactType[]>(CONTACT_TYPES_ENDPOINT).then(response => response.data)
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
