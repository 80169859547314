import { Spinner, Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { useStores } from '@src/hooks/api/useStores'
import { useMemo, useState } from 'react'
import { mapToStatesInfos } from './mappers'
import StateFilter from './StateFilter'
import StateStores from './StateStores'

const StoreListByState = () => {
  const [selectedStateInitials, setSelectedStateInitials] = useState<string>()
  const { data, isLoading } = useStores()

  const states = useMemo(() => {
    if (data) {
      return mapToStatesInfos(data)
    }
    return []
  }, [data])

  return (
    <Container>
      {isLoading ? (
        <VStack width="100%" height="100%" alignItems="center" justifyContent="center">
          <Spinner color="blue" size="xl" />
          <Text marginTop="1rem !important">Carregando lojas...</Text>
        </VStack>
      ) : (
        <VStack alignItems="flex-start" width="full" gridGap={{ base: '1rem', md: '2.6875rem' }}>
          <StateFilter
            selectedStateInitials={selectedStateInitials}
            onClick={setSelectedStateInitials}
            states={states}
          />
          {data?.length && <StateStores selectedStateInitials={selectedStateInitials} storesByState={data} />}
        </VStack>
      )}
    </Container>
  )
}

export default StoreListByState
