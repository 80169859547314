import { Select } from '@chakra-ui/react'
import { useContactTypes } from '@src/hooks/api'
import { useFormContext } from 'react-hook-form'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface ContactTypesSelectProps {
  label: string
  name: string
}

export const ContactTypesSelect = (props: ContactTypesSelectProps) => {
  const { formState, watch, setValue } = useFormContext()
  const { data } = useContactTypes()

  const value = watch(props.name)

  return (
    <FormItem label={props.label} errorMessage={getErrorMessageFromFormState(formState, props.name)}>
      <Select
        placeholder={'Selecione'}
        value={value}
        onChange={e => setValue(props.name, e.target.value)}
        height={'3rem'}
        fontSize={'0.875rem'}
      >
        {data?.map(item => (
          <option key={item.id} value={item.label}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormItem>
  )
}
