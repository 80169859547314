import * as Sentry from '@sentry/nextjs'
import { StoresByState } from '@src/types/strapi/collections/storesByState'
import { api } from './api'

export const GET_STORES_BY_STATES_ENDPOINT = '/stores'

export const getStoresByStates = async () => {
  try {
    const { data } = await api.get<StoresByState[]>(GET_STORES_BY_STATES_ENDPOINT)

    return data
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
