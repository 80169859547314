import { Flex, Heading } from '@chakra-ui/react'
import { Container } from '@components/Container'
import Markdown from '@components/Markdown'
import { StrapiContentWithImage } from '@src/types/strapi'
import { ButtonCommonComponent } from '../Button'
import { ImageCommonComponent } from '../Image'

export const ContentWithImage = (props: StrapiContentWithImage) => {
  return (
    <Container>
      <Flex
        flexDirection={{ base: 'column', lg: props.flip ? 'row-reverse' : 'row' }}
        justifyContent="space-between"
        alignItems={'center'}
        gridGap={{ base: '1rem', md: '3rem' }}
        paddingX={{ base: 0, lg: '1rem', xl: 0 }}
      >
        <Flex
          position={'relative'}
          minWidth={{ base: '19.375rem', xl: '39.5rem' }}
          minHeight={{ base: '13.125rem', xl: '22.25rem' }}
          maxHeight={'22.25rem'}
          borderRadius={'8px'}
        >
          <ImageCommonComponent {...props.image} />
        </Flex>
        <Flex flexDirection={'row'} gridGap={'2.125rem'}>
          <Flex flexDirection={'column'} justifyContent={'center'} gridGap={{ base: '0.8rem', md: '1.5rem' }}>
            {props.title && (
              <Heading
                as="h2"
                fontSize={{ base: '1.25rem', md: '1.75rem' }}
                color={'gray.700'}
                fontWeight={'700'}
                textAlign={{ base: 'center', md: 'left' }}
              >
                {props.title}
              </Heading>
            )}
            <Markdown>{props.description}</Markdown>
            <Flex
              width={'full'}
              gridGap={'1rem'}
              justifyContent={'center'}
              flexDirection={{
                base: 'column',
                md: 'row',
              }}
            >
              {props.buttons?.map(button => (
                <ButtonCommonComponent key={button.id} {...button} renderWithContainer={false} />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}
