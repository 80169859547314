import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export interface FormItemProps {
  label: string
  errorMessage?: string
  isRequired?: boolean
}

export const FormItem = ({ label, children, errorMessage, isRequired = true }: PropsWithChildren<FormItemProps>) => {
  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel fontSize={'0.9375rem'} fontWeight={'600'} color={'gray.700'}>
        {label}
        {isRequired ? '*' : ''}
      </FormLabel>
      {children}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
