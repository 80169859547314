import { Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { Store } from '@src/types/strapi'
import { maskTelephone, maskZipcode } from '@src/utils/mask'
import { memo } from 'react'
import PhoneLink from '../PhoneLink'

interface RenderOpeningHoursProps {
  suffixDay: string
  start: string
  end: string
}

const formatTime = (time: string) => time.replace(':', 'h')

const RenderOpeningHours = (props: RenderOpeningHoursProps) => {
  return (
    <Text fontWeight={400} fontSize="14px" lineHeight="21.07px">
      {formatTime(props.start)} às {formatTime(props.end)} ({props.suffixDay})
    </Text>
  )
}

const StoreDetails = ({ phones, address, openingHours }: Store) => {
  return (
    <HStack
      width="full"
      height="auto"
      flexDirection="row"
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
      gridColumnGap={{ base: '80px', md: '80px', xl: '120px' }}
      gridRowGap="20px"
    >
      <VStack minWidth={{ base: '100%', md: '256px' }} alignItems="flex-start" spacing={1}>
        <Heading as="h4" fontWeight={700} fontSize="14px" lineHeight="21.07px" color="gray.500">
          Endereço
        </Heading>
        <Text fontWeight={400} fontSize="14px" lineHeight="21.07px">
          {address.street},
        </Text>
        <Text wordBreak="break-all" fontWeight={400} fontSize="14px" lineHeight="21.07px">
          {address.number}, {address.neighborhood}, {address.complement} - CEP {maskZipcode(address.zipcode)}
        </Text>
      </VStack>

      <VStack minWidth={{ base: '100%', md: '209px' }} alignItems="flex-start" spacing={1} marginLeft="0 !important">
        <Heading as="h4" fontWeight={700} fontSize="14px" lineHeight="21.07px" color="gray.500">
          Horário de funcionamento
        </Heading>
        <RenderOpeningHours {...openingHours.workingDays} suffixDay={'Seg à Sex'} />
        <RenderOpeningHours {...openingHours.saturday} suffixDay={'Sáb'} />
        {openingHours.holidays && <RenderOpeningHours {...openingHours.holidays} suffixDay={'Dom e Feriados'} />}
      </VStack>

      <VStack minWidth={{ base: '100%', md: '181px' }} alignItems="flex-start" spacing={1} marginLeft="0 !important">
        <Heading as="h4" fontWeight={700} fontSize="14px" lineHeight="21.07px" color="gray.500">
          Telefones
        </Heading>
        {phones.map((phone, index) => (
          <HStack spacing={2} key={index}>
            <Text fontWeight={400} fontSize="14px" lineHeight="21.07px">
              {maskTelephone(phone)}
            </Text>
            <PhoneLink href={`tel:${phone}`} display={{ base: 'block', md: 'none' }} />
          </HStack>
        ))}
      </VStack>
    </HStack>
  )
}

export default memo(StoreDetails)
