import * as Sentry from '@sentry/nextjs'
import { Product } from '@src/types/strapi'
import { api } from './api'

interface RequestFilters {
  title_contains?: string
  firmness?: number
  category?: number
}

export type GetProductsQuantityRequest = RequestFilters

export const COUNT_PRODUCTS_ENDPOINT = '/products/count'

export const getProductsQuantity = async (request: GetProductsQuantityRequest): Promise<number> => {
  try {
    const { data: quantity } = await api.get<number>(COUNT_PRODUCTS_ENDPOINT, { params: request })

    return quantity
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}

interface PaginatedRequest {
  _limit: number
  _start: number
}

export type GetProductsRequest = PaginatedRequest & RequestFilters

export const PRODUCTS_ENDPOINT = '/products'

export const getProducts = async (request: GetProductsRequest): Promise<Product[]> => {
  try {
    const { data: products } = await api.get<Product[]>(PRODUCTS_ENDPOINT, { params: request })

    return products
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}

export const getProduct = async (productId: number): Promise<Product> => {
  try {
    const { data: product } = await api.get<Product>(`${PRODUCTS_ENDPOINT}/${productId}`)

    return product
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
