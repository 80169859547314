import { Flex, IconButton, List, ListItem } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { ArrowLeftSimpleIcon, ArrowRightSimpleIcon } from '@src/icons'
import { PartnersContext } from '@src/react-context/partners-context'
import { useContext, useEffect, useRef } from 'react'
import { states } from './states'

const UfsPartners = () => {
  // const [selectedUf, setSelectedUf] = useState(states[0].acronym);
  const { stateSelected, setStateSelected } = useContext(PartnersContext)
  const listRef = useRef<HTMLUListElement>(null)
  const isUfSelected = (uf: string) => stateSelected.acronym === uf
  const handleNextUf = () => {
    const nextIndex = states.findIndex(state => state.acronym === stateSelected.acronym) + 1
    if (nextIndex < states.length) setStateSelected(states[nextIndex])
  }
  const handlePrevUf = () => {
    const prevIndex = states.findIndex(state => state.acronym === stateSelected.acronym) - 1
    if (prevIndex >= 0) setStateSelected(states[prevIndex])
  }
  // const router = useRouter();
  // useEffect(() => {
  //   router.push({
  //     pathname: router.asPath.split('?')[0],
  //     query: `uf=${selectedUf}`
  //   })
  // }, [selectedUf]);
  useEffect(() => {
    const listItem = listRef.current?.querySelector(`li[data-uf='${stateSelected.acronym}']`)
    listItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }, [stateSelected])
  return (
    <Container>
      <Flex direction="row" width="100%" alignItems="center" gridGap={'1rem'} padding={'1rem 0'}>
        <IconButton
          icon={<ArrowLeftSimpleIcon />}
          variant="unstyled"
          aria-label="next-button"
          backgroundColor="white"
          onClick={handlePrevUf}
        />
        <List
          ref={listRef}
          display={'flex'}
          width={'100%'}
          gridGap={'1rem'}
          justifyContent={'space-between'}
          overflow={'hidden'}
        >
          {states.map(state => (
            <ListItem
              key={state.acronym}
              data-uf={state.acronym}
              fontWeight={isUfSelected(state.acronym) ? 'semibold' : 'normal'}
              color={isUfSelected(state.acronym) ? 'blue.500' : 'GrayText'}
              borderBottom={isUfSelected(state.acronym) ? '2px solid #0D71F0' : 'none'}
              cursor={'pointer'}
              onClick={() => setStateSelected(state)}
            >
              {' '}
              {state.acronym}{' '}
            </ListItem>
          ))}
        </List>
        <IconButton
          icon={<ArrowRightSimpleIcon />}
          variant="unstyled"
          aria-label="next-button"
          backgroundColor="white"
          onClick={handleNextUf}
        />
      </Flex>
    </Container>
  )
}

export default UfsPartners
