import { ProductsListFiltersData } from '@components/Sections/ProductsList/ProductsListFilter'
import { GetProductsRequest } from '@src/service/products'

export const mapFiltersAndPaginationToGetProductsRequest = (
  filters: ProductsListFiltersData,
  currentPage: number,
  itemsPerPage: number
): GetProductsRequest => ({
  _limit: itemsPerPage,
  _start: (currentPage - 1) * itemsPerPage,
  title_contains: filters.name ? filters.name : undefined,
  firmness: filters.firmness,
  category: filters.category ? +filters.category : undefined,
})
