import { HStack, Link, LinkProps, Text } from '@chakra-ui/react'
import { TelephoneIcon } from '@src/icons'
import { memo } from 'react'

export type PhoneLinkProps = LinkProps

const PhoneLink = ({ ...linkProps }: LinkProps) => (
  <Link color="blue.500" {...linkProps}>
    <HStack alignItems="baseline">
      <TelephoneIcon height="15px" width="15px" />
      <Text fontWeight={600} lineHeight="16.8px" size="14px" textDecoration="underline" marginLeft="4px !important">
        Ligar
      </Text>
    </HStack>
  </Link>
)

export default memo(PhoneLink)
