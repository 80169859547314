import * as Sentry from '@sentry/nextjs'
import { Firmness } from '@src/types/strapi'
import { api } from './api'

export const FIRMNESS_ENDPOINT = '/firmness'

export const getFirmnessLevels = async (): Promise<Firmness[]> => {
  try {
    const { data: firmness } = await api.get<Firmness[]>(FIRMNESS_ENDPOINT)

    return firmness
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
