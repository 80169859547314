import { Select } from '@chakra-ui/react'
import { useStates } from '@src/hooks/api'
import { useFormContext } from 'react-hook-form'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface StateSelectProps {
  label: string
  name: string
}

export const StateSelect = (props: StateSelectProps) => {
  const { watch, setValue, formState } = useFormContext()
  const { data } = useStates()

  const value = watch(props.name)

  return (
    <FormItem label={props.label} errorMessage={getErrorMessageFromFormState(formState, 'state')}>
      <Select value={value} onChange={e => setValue(props.name, e.target.value)} height={'3rem'} fontSize={'0.875rem'}>
        <option value={''}>Selecione</option>
        {data?.map(item => (
          <option key={item.initials} value={item.initials}>
            {item.name}
          </option>
        ))}
      </Select>
    </FormItem>
  )
}
