import { StrapiContact } from '@src/types/strapi'
import { ContactUsContainer } from './ContactUsContainer'
import { ContactUsForm } from './ContactUsForm'

export const ContactUs = (props: StrapiContact) => {
  return (
    <ContactUsContainer {...props}>
      <ContactUsForm formTitle={props.formTitle} formSubtitle={props.formSubTitle} />
    </ContactUsContainer>
  )
}
