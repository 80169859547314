import { CloseButton, Flex, HStack, IconButton, Input, Stack, Text, VStack } from '@chakra-ui/react'
import { MagnifyingGlassIcon } from '@src/icons'
import { Dispatch, memo, SetStateAction, useState } from 'react'
import { CategoriesSelect } from './CategoriesSelect'
import { FirmnessSelect } from './FirmnessSelect'

export interface ProductsListFiltersData {
  name?: string
  firmness?: number
  category?: number
}

interface ProductsListFiltersProps {
  setFilters: Dispatch<SetStateAction<ProductsListFiltersData>>
  filters: ProductsListFiltersData
}

const EMPTY_NAME = ''

const ProductsListFilters = ({ filters, setFilters }: ProductsListFiltersProps) => {
  const [search, setSearch] = useState<string>(filters.name || EMPTY_NAME)

  const handleChangeFilter = (key: keyof ProductsListFiltersData, value: any) => {
    setFilters(prevState => ({ ...prevState, [key]: value }))
  }

  const handleClickSearchButton = () => {
    setFilters(prevState => ({ ...prevState, name: search }))
  }

  const handleClearSearchButton = () => {
    setSearch(EMPTY_NAME)
    setFilters(prevState => ({ ...prevState, name: EMPTY_NAME }))
  }

  return (
    <Flex width="full" justifyContent="space-between" gridGap="1rem" flexDirection={{ base: 'column', xl: 'row' }}>
      <Flex width={{ base: 'full', xl: 'auto' }} justifyContent="space-between" alignItems="end">
        <HStack alignItems="self-end" width={{ base: 'full', xl: 'auto' }}>
          <VStack width={{ base: 'full', xl: '471px' }} alignItems="start" marginRight={{ base: '0', xl: '13px' }}>
            <Text fontWeight="bold" fontSize="20px">
              Buscar
            </Text>
            <Stack width="full" justifyContent="center" alignItems="flex-end">
              <Input
                type="text"
                height="52px"
                width="full"
                placeholder="Buscar por nome"
                value={search}
                onInput={e => setSearch(e.currentTarget.value)}
                onKeyUp={event => {
                  if (event.key === 'Enter') {
                    handleClickSearchButton()
                  }
                }}
              />
              <CloseButton
                position="absolute"
                marginTop="0 !important"
                marginRight={2}
                display={filters.name ? 'flex' : 'none'}
                zIndex={2}
                onClick={handleClearSearchButton}
              />
            </Stack>
          </VStack>
          <IconButton
            aria-label="Buscar por nome"
            icon={<MagnifyingGlassIcon />}
            height="52px"
            width="52px"
            color="white"
            backgroundColor="orange.500"
            onClick={handleClickSearchButton}
          />
        </HStack>
      </Flex>

      <Flex gridGap="1rem" height="auto" alignItems="self-end" flexDirection={{ base: 'column', md: 'row' }}>
        <FirmnessSelect
          value={filters.firmness}
          onChange={value => handleChangeFilter('firmness', +value || undefined)}
        />
        <CategoriesSelect
          value={filters.category}
          onChange={value => handleChangeFilter('category', +value || undefined)}
        />
      </Flex>
    </Flex>
  )
}

export default memo(ProductsListFilters)
