const MIN_TELEPHONE_LENGTH = 10
const TELEPHONE_REGEXP = /(\d{2})(\d+)(\d{4})/g
const TELEPHONE_MASK = '($1) $2-$3'

export const maskTelephone = (telephone: string) => {
  const telephoneNumber = telephone.replace(/\D/, '')

  return telephoneNumber.length >= MIN_TELEPHONE_LENGTH
    ? telephoneNumber.replace(TELEPHONE_REGEXP, TELEPHONE_MASK)
    : telephoneNumber
}

const ZIPCODE_LENGTH = 8
const ZIPCODE_REGEXP = /(\d{5})(\d{3})/
const ZIPCODE_MASK = '$1-$2'

export const maskZipcode = (zipcode: string) => {
  const zipcodeNumber = zipcode?.replace(/\D/, '') || ''

  return zipcodeNumber.length === ZIPCODE_LENGTH ? zipcodeNumber.replace(ZIPCODE_REGEXP, ZIPCODE_MASK) : zipcodeNumber
}
