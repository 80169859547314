import * as Sentry from '@sentry/nextjs'
import { api } from './api'

export const makeCitiesEndpoint = (stateInitials: string) => `/states/${stateInitials}/cities`

export const getCities = async (stateInitials: string): Promise<string[]> => {
  try {
    return api.get<string[]>(makeCitiesEndpoint(stateInitials)).then(response => response.data)
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
