import { Select, Spinner } from '@chakra-ui/react'
import { useCities } from '@src/hooks/api'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface CitySelectProps {
  label: string
  name: string
}

export const CitySelect = (props: CitySelectProps) => {
  const { watch, setValue, formState } = useFormContext()
  const state = watch('state')
  const value = watch(props.name)

  useEffect(() => {
    setValue(props.name, undefined)
  }, [state])

  const { data, isLoading } = useCities(state)

  return (
    <FormItem label={props.label} errorMessage={getErrorMessageFromFormState(formState, props.name)}>
      <Select
        value={value}
        onChange={e => setValue(props.name, e.target.value)}
        height={'3rem'}
        icon={isLoading ? <Spinner color={'blue.500'} /> : undefined}
        placeholder={'Selecione'}
        isDisabled={!state}
        textTransform={'capitalize'}
        fontSize={'0.875rem'}
      >
        {data?.map(item => (
          <option key={item} value={item} style={{ textTransform: 'capitalize' }}>
            {item.toLowerCase()}
          </option>
        ))}
      </Select>
    </FormItem>
  )
}
