import { Button, Flex, Grid, Spinner, Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { PartnersContext } from '@src/react-context/partners-context'
import { getPartners } from '@src/service/partners'
import { useContext, useEffect, useState } from 'react'
import PartnerCard from '../PartnerCard'

const PartnersList = () => {
  const [page, setPage] = useState(1)
  const [totalPartners, setTotalPartners] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { partners, setPartners, stateSelected } = useContext(PartnersContext)

  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true)
      getPartners({ uf: stateSelected.acronym, page: 1 }).then(partnersResponse => {
        setPartners(partnersResponse.partners)
        setTotalPartners(partnersResponse.totalPartners)
        setIsLoading(false)
        setPage(1)
      })
    }

    setTimeout(fetchPartners, 1000)
  }, [stateSelected])

  const handleViewMore = async () => {
    const nextPage = page + 1
    const morePartners = await getPartners({ uf: stateSelected.acronym, page: nextPage })
    setPartners([...partners, ...morePartners.partners])
    setPage(nextPage)
  }

  return (
    <>
      <Container>
        <Flex direction={'column'} padding={'0.5rem'} gridGap={'1rem'} alignItems={'center'}>
          {isLoading && (
            <VStack width="100%" height="100%" alignItems="center" justifyContent="center">
              <Spinner color="blue" size="xl" />
              <Text marginTop={'1rem !important'}>Carregando parceiros...</Text>
            </VStack>
          )}
          <Text fontSize={'1.25rem'} fontWeight={'700'} width={'100%'}>
            {`${stateSelected.name} (${totalPartners})`}
          </Text>
          <Grid
            templateColumns={{
              lg: 'repeat(4, 1fr)',
              md: 'repeat(3, 1fr)',
              sm: 'repeat(2, 1fr)',
              base: 'repeat(1, 1fr)',
            }}
            justifyContent={'center'}
            rowGap={'1rem'}
            columnGap={'1.5rem'}
          >
            {partners.length > 0 && partners.map((partner, index) => <PartnerCard key={index} partner={partner} />)}
          </Grid>
          <Button
            variant="outline"
            colorScheme={'blue'}
            width={{ base: '100%', md: '17.3125rem' }}
            height={'3.375rem'}
            marginTop={'1rem'}
            onClick={handleViewMore}
          >
            ver mais
          </Button>
        </Flex>
      </Container>
    </>
  )
}

export default PartnersList
