import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { useFactories } from '@src/hooks/api/useFactories'
import { PinLocationIcon } from '@src/icons/pin-location'
import { StrapiFactories } from '@src/types/strapi/components/factories'
import { maskZipcode } from '@src/utils/mask'
import Image from 'next/image'

export const FactoriesList = ({ title, factoryImage }: StrapiFactories) => {
  const { data: industries = [], isLoading } = useFactories()

  const formatData = (address: string) => {
    return address?.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase())
  }

  return (
    <Container>
      {!isLoading && industries?.length && (
        <Box>
          <Text
            fontSize={{ base: '1.5rem', lg: '2rem' }}
            fontWeight="700"
            textAlign="center"
            color="gray.700"
            marginBottom="1.25rem"
          >
            {title}
          </Text>
          <Stack flexDirection={{ base: 'column', lg: 'row' }}>
            <Box maxWidth="358px" maxHeight="358px" margin="auto">
              <Image src={factoryImage.url} width="358px" height="358px" />
            </Box>
            <HStack
              width={{ base: '100%', lg: '59.375rem' }}
              minHeight="28.125rem"
              gridGap={{ base: '5', lg: '0' }}
              flexWrap="wrap"
              marginY="2rem"
              spacing={0}
            >
              {industries?.map(industry => (
                <HStack
                  key={industry.id}
                  width={{ base: '100%', md: '9rem', lg: '18.0625rem' }}
                  minHeight="6.25rem"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <PinLocationIcon color="blue.500" />
                  <VStack width="100%" alignItems="flex-start" spacing={4}>
                    <Box>
                      <Text fontSize="1rem" fontWeight="700" color="blue.800">
                        {industry.name}
                      </Text>
                      <Text fontSize=".875rem" fontWeight="400" lineHeight={0.75} color="gray.700">
                        {formatData(industry.type).replace(/ E /g, ' e ')}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize=".875rem" fontWeight="400" letterSpacing={0} color="gray.700">
                        {formatData(industry.address)} {formatData(industry.addressComplement)}
                      </Text>
                      {industry.zipcode && (
                        <Text fontSize=".875rem" fontWeight="400" letterSpacing={0} color="gray.700">
                          CEP {maskZipcode(industry.zipcode)}
                        </Text>
                      )}
                    </Box>
                  </VStack>
                </HStack>
              ))}
            </HStack>
          </Stack>
        </Box>
      )}
    </Container>
  )
}
