import { ProductsListFiltersData } from '@components/Sections/ProductsList/ProductsListFilter'
import { getProducts, PRODUCTS_ENDPOINT } from '@src/service/products'
import { Product } from '@src/types/strapi'
import { makeQueryStringFromObject } from '@src/utils/make-query-string-from-object'
import { useMemo } from 'react'
import useSWR from 'swr'
import { mapFiltersAndPaginationToGetProductsRequest } from './mappers'

export const useProducts = (filters: ProductsListFiltersData, currentPage: number, itemsPerPage: number) => {
  const productsRequestData = useMemo(
    () => mapFiltersAndPaginationToGetProductsRequest(filters, currentPage, itemsPerPage),
    [filters, currentPage]
  )

  return useSWR<Product[]>(`${PRODUCTS_ENDPOINT}?${makeQueryStringFromObject(productsRequestData)}`, () =>
    getProducts(productsRequestData)
  )
}
