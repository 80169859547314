import { Box } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { PartnersContext } from '@src/react-context/partners-context'
import { Partner } from '@src/types/strapi/collections/partners'
import { useContext, useEffect, useState } from 'react'

const PartnersGoogleMaps = () => {
  const { partners, stateSelected, partnerSelected, setPartnerSelected } = useContext(PartnersContext)
  const [center, setCenter] = useState({ lat: stateSelected.latitude, lng: stateSelected.longitude })
  const [zoom, setZoom] = useState(6)
  useEffect(() => {
    const { latitude, longitude } = stateSelected
    setCenter({ lat: latitude, lng: longitude })
  }, [stateSelected])
  useEffect(() => {
    const { latitude, longitude } = partnerSelected
    if (latitude && longitude) {
      setCenter({ lat: latitude, lng: longitude })
      setZoom(10)
    }
  }, [partnerSelected])
  useEffect(() => {
    setPartnerSelected({} as Partner)
    setZoom(6)
  }, [partners])

  return (
    <Container>
      <Box width={'100%'} border={'2px solid #DCDDE3'} borderRadius={'1rem'} padding={'1rem'}>
        <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '30rem' }}
            center={center}
            zoom={zoom}
            options={{ disableDefaultUI: true, zoomControl: false }}
          >
            {partners.map(
              (partner, index) =>
                partner.latitude &&
                partner.longitude && (
                  <Marker
                    key={index}
                    position={{ lat: partner.latitude, lng: partner.longitude }}
                    clickable={true}
                    title={partner.business_name}
                    icon={'./google-marker-blue.svg'}
                  />
                )
            )}
          </GoogleMap>
        </LoadScript>
      </Box>
    </Container>
  )
}

export default PartnersGoogleMaps
