import { Text, VStack } from '@chakra-ui/react'
import { Container } from '@components/Container'
import { StrapiCertificationGroup } from '@src/types/strapi'
import { memo } from 'react'
import Certification from './Certification/Certification'

const CertificationGroup = ({ title, items }: StrapiCertificationGroup) => {
  return (
    <Container flexDirection={'column'} gridGap={{ base: '1rem', md: '3rem' }}>
      <Text fontSize={{ base: '1.75rem', md: '2rem' }} fontWeight={'700'} fontFamily="Inter" color="gray.700">
        {title}
      </Text>
      <VStack gridGap={{ base: '0.5rem', md: '1.5rem' }}>
        {items.map(certification => (
          <Certification key={certification.id} {...certification} />
        ))}
      </VStack>
    </Container>
  )
}

export default memo(CertificationGroup)
