import { Box } from '@chakra-ui/react'
import { Container } from '@components/Container'
import useWindowSize from '@src/hooks/useWindowSize'
import { StrapiCover } from '@src/types/strapi'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Markdown from '../../Markdown'
import { ButtonCommonComponent } from '../Button'
import { colorDescription } from './utils'

const RenderCoverTitle = (props: StrapiCover) => {
  if (!props.description) {
    return null
  }

  return (
    <Box
      maxWidth="580px"
      color={colorDescription[props.descriptionColor]}
      textAlign={{
        base: 'center',
        md: props.flip ? 'right' : 'left',
      }}
    >
      <Markdown>{props.description}</Markdown>
    </Box>
  )
}

const RenderCoverButton = (props: StrapiCover) => {
  if (!props.button) {
    return null
  }

  return <ButtonCommonComponent {...props.button} renderWithContainer={false} />
}

export const CoverCommonComponent = (props: StrapiCover) => {
  const router = useRouter()
  const { isMobile } = useWindowSize()
  const img = isMobile ? props.image.mobile_image : props.image.desktop_image

  const handleCoverClick = () => {
    if (!props.link) return

    if (props.isExternal) {
      window.open(props.link, '_blank', 'noopener noreferrer')
      return
    }

    router.push(props.link)
  }

  return (
    <Box position="relative" onClick={handleCoverClick} cursor={props.link ? 'pointer' : 'default'}>
      <Box width="full" height={{ base: '400px', lg: img.height }}>
        <Image src={img.url} alt={img.name} sizes="100vw" layout="fill" />
      </Box>
      <Container position="absolute" height={'100%'} top="0px" justifyContent="flex-start">
        <Container
          flexDirection="column"
          alignItems={props.flip ? 'flex-end' : 'flex-start'}
          gridGap={{ base: '1.5rem', md: '2rem', lg: '2.625rem' }}
        >
          <RenderCoverTitle {...props} />
          <RenderCoverButton {...props} />
        </Container>
      </Container>
    </Box>
  )
}
