import * as Sentry from '@sentry/nextjs'
import { State } from '@src/types/strapi'
import { api } from './api'

export const STATES_ENDPOINT = '/states'

export const getStates = async (): Promise<State[]> => {
  try {
    return api.get<State[]>(STATES_ENDPOINT).then(response => response.data)
  } catch (error) {
    Sentry.captureException(error)

    throw error
  }
}
