import { api } from './api'

export interface ContactUsFormPayload {
  contactType: string
  name: string
  email: string
  telephone: string
  state: string
  city: string
  subject: string
  message: string
}

const CONTACT_US_ENDPOINT = '/contacts/send-email'

export const sendEmailContactUs = async (data: ContactUsFormPayload): Promise<void> => {
  await api.post(CONTACT_US_ENDPOINT, data)
}
