import { Textarea, TextareaProps } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { FormItem } from './FormItem'
import { getErrorMessageFromFormState } from './utils'

export interface InputTextAreaProps extends TextareaProps {
  label: string
  name: string
}

export const InputTextArea = ({ label, name, ...props }: InputTextAreaProps) => {
  const { register, formState } = useFormContext()

  return (
    <FormItem label={label} errorMessage={getErrorMessageFromFormState(formState, name)}>
      <Textarea {...register(name)} placeholder={'Digite sua mensagem'} {...props} />
    </FormItem>
  )
}
