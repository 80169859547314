import ButtonStrapi from '@components/ButtonStrapi'
import Content from '@components/Content'
import { GenerateLink } from '@components/GenerateLink'
import useWindowSize from '@src/hooks/useWindowSize'
import cn from 'classnames'
import Image from 'next/image'
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ISlide, ISlider } from './Slider.interface'
import styles from './Slider.module.scss'

const SlideButton = ({ content, active, onClick, index }) => {
  const handleClick = useCallback(() => {
    onClick(index)
  }, [onClick, index])

  return (
    <div
      role="button"
      onClick={handleClick}
      tabIndex={0}
      className={cn(styles.slideButton, { [styles.slideButtonActive]: active })}
    >
      {content}
    </div>
  )
}

const Slider: React.FC<ISlider> = ({ slide_interval, slides }) => {
  const slideInterval = useRef(slide_interval * 1000)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const { isMobile, width } = useWindowSize()
  const interval = useRef<any>(0)

  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const sliderStyle = useMemo<CSSProperties>(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      width: `${(width || 1) * slides.length}px`,
      transform: `translateX(-${(width || 1) * activeSlideIndex}px)`,
      transition: 'transform ease-out .2s',
    }),
    [width, activeSlideIndex]
  )

  const renderSlide = ({ title, heading, image, image_mobile, button, id, link }: ISlide) => {
    const RenderContent = useCallback(() => {
      return (
        <>
          <Content className={styles.content}>
            <div className={styles.textContainer}>
              <span className={styles.heading}>{heading}</span>
              <h1 className={styles.title}>{title}</h1>
            </div>
            {button && <ButtonStrapi {...button} buttonProps={{ backgroundColor: 'white', variant: 'outline' }} />}
          </Content>

          {isMobile ? (
            <Image src={image_mobile.url} layout="intrinsic" height={image_mobile.height} width={image_mobile.width} />
          ) : (
            <Image objectFit="fill" width={1240} height={571} src={image.url} className={styles.image} />
          )}
        </>
      )
    }, [title, heading, image, image_mobile, button, id, link, isMobile])

    return (
      <div key={id} className={cn(styles.slide)}>
        {link ? (
          <GenerateLink {...link}>
            <RenderContent />
          </GenerateLink>
        ) : (
          <RenderContent />
        )}
      </div>
    )
  }

  const createSlideInterval = useCallback(
    index => {
      clearInterval(interval.current)
      interval.current = setInterval(() => {
        setActiveSlideIndex(index)
      }, slideInterval.current)
    },
    [slides]
  )

  const handleClick = useCallback((index: number) => {
    setActiveSlideIndex(index)
  }, [])

  const handleNextSlide = useCallback(() => {
    const newIndex = (activeSlideIndex + 1) % slides.length
    setActiveSlideIndex(newIndex)
    createSlideInterval(newIndex)
  }, [activeSlideIndex, createSlideInterval, slides])

  const handlePreviousSlide = useCallback(() => {
    const newIndex = (activeSlideIndex === 0 ? slides.length - 1 : activeSlideIndex - 1) % slides.length
    setActiveSlideIndex(newIndex)
    createSlideInterval(newIndex)
  }, [activeSlideIndex, createSlideInterval])

  const renderSlideButtons = ({ id }: ISlide, index: number) => {
    return (
      <SlideButton
        key={id}
        index={index}
        onClick={handleClick}
        active={activeSlideIndex === index}
        content={index + 1}
      />
    )
  }

  const handleTouchStart = useCallback(e => {
    setTouchStart(e.targetTouches[0].clientX)
  }, [])

  const handleTouchMove = useCallback(e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }, [])

  const handleTouchEnd = useCallback(() => {
    if (touchStart - touchEnd > 150) return handleNextSlide()
    if (touchStart - touchEnd < -150) return handlePreviousSlide()
  }, [handlePreviousSlide, handleNextSlide, touchStart, touchEnd])

  useEffect(() => {
    interval.current = setInterval(() => {
      setActiveSlideIndex(currentSlide => (currentSlide + 1) % slides.length)
    }, slideInterval.current)
    return () => clearInterval(interval.current)
  }, [createSlideInterval, interval.current])

  return (
    <div
      className={styles.slider}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div style={sliderStyle}>{slides.map(renderSlide)}</div>
      {!isMobile && (
        <div className={styles.controller}>
          {slides.map(renderSlideButtons)}
          <div className={styles.arrowController}>
            <div role="button" onClick={handlePreviousSlide}>
              <img src="/slider-left.svg" width={8} height={8} />
            </div>
            <div role="button" onClick={handleNextSlide}>
              <img src="/slider-right.svg" width={8} height={8} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Slider
