import { Button } from '@chakra-ui/core'
import Content from '@components/Content'
import Form from '@components/Form'
import InputWithSlider, { propsUnstyled } from '@components/InputWithSlider'
import InvestimentChart, { formatValueMonetary } from '@components/InvestimentChart'
import { ISendValues } from '@components/SimulatorCard/SimulatorCard.interface'
import TextInput from '@components/TextInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/nextjs'
import useWindowSize from '@src/hooks/useWindowSize'
import { api } from '@src/service/api'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import {
  IBar,
  ICardSimulator,
  IInvestimentSimulator,
  IItemInputSlider,
  InvestmentSimulationResult,
} from './InvestimentSimulator.interface'
import styles from './InvestimentSimulator.module.scss'

const RenderInputMonthly: FC<IItemInputSlider & { name: string }> = ({ label, max, min, name }) => {
  const { watch } = useFormContext()

  const onInput = (e: any) =>
    e.target.value.length > max.toString().length
      ? (e.target.value = e.target.value.slice(0, max.toString().length))
      : 0

  const monthlyValue = min.toString()
  const slidevalue = watch(name)
  const monthlySuffixLabel = useMemo(() => {
    return slidevalue > 1 ? 'meses' : 'mês'
  }, [slidevalue])

  return (
    <div className={styles.investmentTime}>
      <TextInput
        name={name}
        label={label}
        defaultValue={monthlyValue}
        height="43px"
        minWidth="50px"
        type="number"
        variant="unstyled"
        {...propsUnstyled}
        onInput={onInput}
      />
      <div className={styles.investmentTimeValue}>{monthlySuffixLabel}</div>
    </div>
  )
}

const CardInvestimentSimulator: FC<ICardSimulator> = ({
  title,
  initialDeposit,
  monthlyDeposit,
  investmentTime,
  buttonLabel,
  onSubmit,
  loading,
  form,
}) => {
  const RenderButton = ({ label }) => {
    return (
      <Button
        marginTop="42px"
        size="lg"
        backgroundColor="#1F81D1"
        w="100%"
        height="55px"
        color="#FFF"
        variantColor="blue"
        type="submit"
        isLoading={loading}
      >
        {label}
      </Button>
    )
  }
  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className={styles.simulator}>
        <p className={styles.title}>{title}</p>
        <div className={styles.valueWithSlider}>
          <InputWithSlider name="initialDeposit" {...initialDeposit}></InputWithSlider>
          <InputWithSlider name="monthlyDeposit" {...monthlyDeposit}></InputWithSlider>
          <InputWithSlider name="investmentTime" {...investmentTime}>
            <RenderInputMonthly name="investmentTime" {...investmentTime} />
          </InputWithSlider>
          <RenderButton label={buttonLabel} />
        </div>
      </div>
    </Form>
  )
}
interface INumberGeneric {
  [key: string]: number | undefined
}

const yupValidation = ({ initialDeposit, monthlyDeposit, investmentTime }) => {
  const messageBetweenValueMonetary = (props: INumberGeneric) => {
    return `Insira um valor entre R$ ${formatValueMonetary(props.min)} e R$ ${formatValueMonetary(props.max)}`
  }

  const messageBetweenValueMonth = (props: INumberGeneric) => {
    return `Insira um valor entre  ${props.min} e ${props.max} meses`
  }

  const validSlider = (props, messageBetweenValue) =>
    yup.string().test('isValid', messageBetweenValue(props), inputValue => {
      if (!inputValue) {
        inputValue = props.min
      }

      if (!inputValue) return false

      return parseFloat(inputValue) <= props.max && parseFloat(inputValue) >= props.min
    })

  return {
    initialDeposit: validSlider(initialDeposit, messageBetweenValueMonetary),
    monthlyDeposit: validSlider(monthlyDeposit, messageBetweenValueMonetary),
    investmentTime: validSlider(investmentTime, messageBetweenValueMonth),
  }
}

export const InvestimentSimulator: FC<IInvestimentSimulator> = pageProps => {
  const investimentChartRef = useRef<HTMLDivElement>(null)
  const { isDesktop } = useWindowSize()
  const [loading, setLoading] = useState(false)

  const defaultValues = ({ initialDeposit, monthlyDeposit, investmentTime }) => ({
    initialDeposit: initialDeposit.min.toString(),
    monthlyDeposit: monthlyDeposit.min.toString(),
    investmentTime: investmentTime.min.toString(),
  })

  const schema = yup.object().shape(yupValidation(pageProps.card))
  const form = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: defaultValues(pageProps.card),
  })

  const [bars, setBars] = useState<IBar[]>(pageProps.chart.bars)

  pageProps.chart.bars = pageProps.chart.bars.map(bar => ({ ...bar, value: 0 }))

  const onSubmit = useCallback(async formData => {
    const { investmentTime, initialDeposit, monthlyDeposit } = formData

    try {
      setLoading(true)

      const payload = {
        investmentTime: Number(investmentTime),
        initialValue: Number(initialDeposit.replace(',', '.')),
        monthValue: Number(monthlyDeposit.replace(',', '.')),
        preFixedAnnualRates: {
          lessThan36: pageProps.chart.bars[2].preFixedRate1,
          greaterEqual36: pageProps.chart.bars[2].preFixedRate2,
        },
        cdiRates: {
          postFixed: pageProps.chart.bars[1].cdiTable,
          other: pageProps.chart.bars[3].cdiTable,
        },
      }

      const { data } = await api.post<InvestmentSimulationResult>('/pages/simulation/investiment', payload)

      const postFixedLabel = pageProps.chart.bars[1].label + ' ' + data.postFixed.cdiRate + '%'
      const preFixedLabel = pageProps.chart.bars[2].label + ' ' + data.preFixed.annualRate + '%'
      const otherLabel = pageProps.chart.bars[3].label + ' ' + data.other.cdiRate + '%'

      let newBars = [...bars]

      newBars[0].result = data.savings
      newBars[0].value = data.savings.netInvestimentValue

      newBars[1].label = postFixedLabel
      newBars[1].result = data.postFixed
      newBars[1].value = data.postFixed.netInvestimentValue

      newBars[2].label = preFixedLabel
      newBars[2].result = data.preFixed
      newBars[2].value = data.preFixed.netInvestimentValue

      newBars[3].label = otherLabel
      newBars[3].result = data.other
      newBars[3].value = data.other.netInvestimentValue

      newBars = newBars.sort((barA: IBar, barB: IBar) => {
        const valueA = barA?.value ?? 0
        const valueB = barB?.value ?? 0

        return valueA - valueB
      })

      setBars(newBars)
    } catch (ex) {
      Sentry.captureException(ex)
    } finally {
      setLoading(false)
    }
  }, [])

  const renderDesktop: FC<IInvestimentSimulator> = ({ card, chart }) => {
    return (
      <div className={styles.fullCard}>
        <div className={styles.simulatorFrame}>
          <CardInvestimentSimulator {...card} loading={loading} onSubmit={onSubmit} form={form} />
          <div className={styles.info}>{card.info}</div>
        </div>

        <div className={styles.chartFrame}>
          <div className={styles.info}>{chart.info}</div>
          <InvestimentChart {...chart} bars={bars} />
        </div>
      </div>
    )
  }

  const renderMobile: FC<IInvestimentSimulator> = ({ card, chart }) => {
    const handleBackInvestimentChart = async (params: ISendValues) => {
      await onSubmit(params)
      investimentChartRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    return (
      <div className={styles.fullCard}>
        <div className={styles.simulatorFrame}>
          <CardInvestimentSimulator {...card} loading={loading} onSubmit={handleBackInvestimentChart} form={form} />
        </div>

        <div className={styles.chartFrame} ref={investimentChartRef}>
          <InvestimentChart {...chart} bars={bars} />
        </div>
        <div className={styles.info}>{chart.info}</div>
        <div className={styles.info}>{card.info}</div>
      </div>
    )
  }

  return (
    <Content className={styles.content}>
      {isDesktop ? renderDesktop({ ...pageProps }) : renderMobile({ ...pageProps })}
    </Content>
  )
}

export default InvestimentSimulator
